import React from 'react';

const SelectedX = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5815 12.3536L11.9351 12L11.5815 11.6465L7.12503 7.18994C7.05741 7.11476 7.02071 7.01668 7.0225 6.9154C7.02433 6.81178 7.06631 6.7129 7.1396 6.63962C7.21288 6.56633 7.31175 6.52435 7.41538 6.52252C7.51666 6.52074 7.61474 6.55743 7.68992 6.62505L12.1464 11.0816L12.5 11.4351L12.8535 11.0816L17.3101 6.62504C17.3852 6.55743 17.4833 6.52074 17.5846 6.52252C17.6882 6.52435 17.7871 6.56633 17.8604 6.63962C17.9337 6.71291 17.9756 6.81178 17.9775 6.91541C17.9793 7.01667 17.9426 7.11476 17.875 7.18994L13.4184 11.6465L13.0649 12L13.4184 12.3536L17.8824 16.8176L17.8887 16.8238L17.8951 16.8298C17.9344 16.8664 17.9659 16.9106 17.9878 16.9597C18.0097 17.0087 18.0214 17.0617 18.0224 17.1154C18.0233 17.1691 18.0134 17.2225 17.9933 17.2723C17.9732 17.3221 17.9433 17.3673 17.9053 17.4053C17.8673 17.4433 17.8221 17.4732 17.7722 17.4933C17.7224 17.5135 17.6691 17.5233 17.6154 17.5224C17.5617 17.5215 17.5087 17.5097 17.4596 17.4878C17.4106 17.466 17.3664 17.4344 17.3298 17.3951L17.3238 17.3887L17.3175 17.3825L12.8535 12.9185L12.5 12.5649L12.1464 12.9185L7.68244 17.3825L7.6762 17.3887L7.67018 17.3951C7.63356 17.4344 7.5894 17.466 7.54034 17.4878C7.49127 17.5097 7.4383 17.5215 7.38459 17.5224C7.33089 17.5233 7.27754 17.5135 7.22773 17.4933C7.17793 17.4732 7.13268 17.4433 7.0947 17.4053C7.05671 17.3673 7.02677 17.3221 7.00665 17.2723C6.98653 17.2225 6.97665 17.1691 6.9776 17.1154C6.97855 17.0617 6.9903 17.0087 7.01217 16.9597C7.03403 16.9106 7.06555 16.8664 7.10485 16.8298L7.1113 16.8238L7.11754 16.8176L11.5815 12.3536Z"
                fill="#6B7280" stroke="#6B7280"/>
        </svg>
    );
};

export default SelectedX;