import React from 'react';
import NavigationMyFiles from "../components/dashboard/utiles/NavigationMyFiles";
import ContentMessages from "../components/dashboard/utiles/ContentMessages";
import ContentFiles from "../components/dashboard/utiles/ContentFiles";

const MyFilesPage = () => {
    return (
        <div>
            <NavigationMyFiles/>
            <ContentFiles/>
        </div>
    );
};

export default MyFilesPage;