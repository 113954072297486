import React, {useEffect, useState} from 'react';
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import ModalConfirm from "../components/modal/modalConfirm";
import CheckboxLabel from "../components/buttons/CheckboxLabel";
import ButtonDelete from "../components/buttons/ButtonDelete";
import axiosConfig from "../axiosConfig";
import {toast, ToastContainer} from "react-toastify";

const BillingInfo = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [renewalDate, setRenewalDate] = useState(null);
    const [isSubscriptionCanceled, setIsSubscriptionCanceled] = useState(false);

    const handleDiscardChangesClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    ///// GET DATA FROM BILLING PAGE
    useEffect(() => {
        if (!isSubscriptionCanceled) {
            axiosConfig.get('/billing/get-invoices')
                .then(responseSettings => {
                    const data = responseSettings.data;

                    if (data) {
                        const renewalDate = data.renewalDate
                        const isCanceled = data.isCanceled
                        if (isCanceled === false) {
                            setRenewalDate(renewalDate);
                        } else {
                            setIsSubscriptionCanceled(true);
                        }
                    }
                })
                .catch(error => {
                    toast.error('Error fetching data', error.data);
                });
        }
    }, [isSubscriptionCanceled]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const cancelSubscription = () => {
        axiosConfig.post('/billing/subscription-action')
            .then(response => {
                const { isCanceled, renewalDate } = response.data;
                if (isCanceled === false) {
                    setRenewalDate(renewalDate);
                } else {
                    setIsSubscriptionCanceled(true);
                }
            })
            .catch(error => {
                toast.error('Error canceling subscription', error.data);
            });
    };

    return (
        <>
            <div className="content flex flex-col desktop:h-full mobile:p-6 bg-gray2">
                <div className="space-y-9">
                    <h6 className="text-h6 text-dark4 ">Billing info</h6>
                    <h6 className="text-h6 text-dark4">Renewal</h6>
                    <div
                        className="subscription bg-blueLight5 rounded cursor-pointer mobXS:w-full tabMD:w-1/2 lgDesktop:w-3/5">
                        <div className="px-6 py-8 desktop:flex-row desktop:flex gap-20">
                            <div className="">
                                <p className="text-bodyMR text-black">Subscription available until</p>
                                <p className="text-bodyLSB text-primary mb-6">{formatDate(renewalDate)}</p>
                            </div>
                            <ButtonSecondary text="Cancel subscription" onClick={handleDiscardChangesClick}
                                             customClass="mobile:hidden text-primary text-bodyMM ml-auto mt-4"></ButtonSecondary>
                            <div className="desktop:hidden">
                                <ButtonPrimary text="Cancel subscription" padding="px-[8px] py-[10px]"
                                               action={handleDiscardChangesClick}/>
                            </div>
                        </div>
                    </div>
                    <div className="my-cards space-y-5 cursor-pointer hidden">
                        <h6 className="text-h6 text-dark4">My Cards</h6>

                        <div className="desktop:flex desktop:flex-row gap-5 mobile:space-y-6">

                            <div
                                className="card-row bg-white rounded mobile:flex-row mobile:flex mobXS:w-full tabMD:w-1/2">
                                <a href="/dashboard/billing/my-cards"
                                   className="desktop:hidden flex-row flex gap-5 px-[15px] py-[22px]">
                                    <img src="/mastercard.svg" alt=""/>
                                    <div className="flex flex-col">
                                        <p className="text-bodyMR text-dark4">MASTERCARD **** 1234</p>
                                        <p className="text-bodyESR text-dark6">Expires at: February 2026</p>
                                    </div>
                                    <img src="/arrow-right.svg" className="ml-auto" alt=""/>
                                </a>
                                <div className="mobile:hidden">
                                    <div className="flex-row flex gap-5 px-[15px] py-[22px]">
                                        <img src="/mastercard.svg" alt=""/>
                                        <div className="flex flex-col">
                                            <p className="text-bodyMR text-dark4">MASTERCARD **** 1234</p>
                                            <p className="text-bodyESR text-dark6">Expires at: February 2026</p>
                                        </div>
                                    </div>
                                    <div className="delete-main-card border-t-2">
                                        <div className="flex flex-row px-6 pt-2 pb-2 gap-5">
                                            <CheckboxLabel label="Main Card"/>
                                            <div className="ml-auto">
                                                <ButtonDelete className="border-0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="card-row bg-white rounded mobile:flex-row mobile:flex mobXS:w-full tabMD:w-1/2">
                                <a href="/dashboard/billing/my-cards"
                                   className=" desktop:hidden flex-row flex gap-5 px-[15px] py-[22px]">
                                    <img src="/visa.svg" alt=""/>
                                    <div className="flex flex-col">
                                        <p className="text-bodyMR text-dark4">VISA **** 1234</p>
                                        <p className="text-bodyESR text-dark6">Expires at: February 2026</p>
                                    </div>

                                    <img src="/arrow-right.svg" className="ml-auto" alt=""/>
                                </a>
                                <div className="mobile:hidden">
                                    <div className="flex-row flex gap-5 px-[15px] py-[22px]">
                                        <img src="/visa.svg" alt=""/>
                                        <div className="flex flex-col">
                                            <p className="text-bodyMR text-dark4">VISA **** 1234</p>
                                            <p className="text-bodyESR text-dark6">Expires at: February 2026</p>
                                        </div>
                                    </div>
                                    <div className="delete-main-card border-t-2">
                                        <div className="flex flex-row px-6 pt-2 pb-2 gap-5">
                                            <CheckboxLabel label="Main Card"/>
                                            <div className="ml-auto">
                                                <ButtonDelete className="border-0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="card-new bg-white rounded flex-col flex px-[15px] py-[22px] mobXS:w-full tabMD:w-1/2">
                                <div className="flex-row flex justify-center gap-2">
                                    <img src="/plus.svg" alt="" className="w-[20px]"/>
                                    <a href="" className="text-bodyMM text-primary">Add new card</a>
                                </div>
                                <p className="text-bodyESR text-dark6 text-center py-2">You will be redirected to the
                                    payment
                                    processor
                                    to validate the card</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttons-action mt-auto mobile:px-6 hidden">
                    <div className="flex-row flex gap-2 cursor-pointer mobXS:justify-center mobile:pt-8 desktop:mb-8">
                        <ButtonSecondary text="Update" customClass="bg-primary text-white text-center justify-center"
                                         width="mobXS:w-full tabMD:w-1/4 desktop:w-[150px]"
                                         padding="px-[5px] py-[10px]"
                        />
                        <ButtonSecondary text="Discard changes"
                                         customClass="bg-white border text-dark4 text-center justify-center"
                                         width="mobXS:w-full tabMD:w-1/4 desktop:w-[150px]"
                                         padding="px-[5px] py-[10px]" onClick={handleDiscardChangesClick}/>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {isModalOpen && (
                <ModalConfirm titleModal="Are you sure you want to discard the changes?" closeModal={closeModal}
                              updateAction={cancelSubscription}/>
            )}
        </>
    );
};

export default BillingInfo;