import React, { useState } from 'react';
import axios from 'axios';
import ButtonSecondary from "../buttons/ButtonSecondary";
import axiosConfig from "../../axiosConfig";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";

const ModalUploadFile = ({ isOpen, onClose }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const { REACT_APP_BASE } = process.env;

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadFile = async () => {
        const accessToken = Cookies.get('AccessToken');
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            if (!accessToken) {
                return;
            }

            // const config = {
            //     withCredentials: true,
            // };

            await axiosConfig().get(
                `http://localhost:80/api/actions/file-manager/get-presigned-url?filename=${formData}`,
            );

            toast.success('File uploaded successfully');
            setUploadSuccess(true);
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 2000);
        } catch (error) {
            toast.error('Error uploading file:', error);
        }
    };

    const handleCancel = () => {
        onClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
            <div className={`${uploadSuccess ? 'bg-greenLight' : 'bg-white'} p-8 rounded-lg mx-6`}>
                {uploadSuccess ? (
                    <div>
                        <p className="text-bodyMR text-dark4 mb-4 text-left">File uploaded successfully</p>
                    </div>
                ) : (
                    <div>
                        <h2 className="text-h6 text-dark4 mb-4 text-left">Upload a file</h2>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className=" p-2 w-full rounded mt-2"
                        />
                        <div className="flex justify-center mt-4 space-x-6">
                            <ButtonSecondary
                                text="Upload"
                                customClass="bg-primary text-white text-bodyMR p-1 px-[22px] py-[6px]"
                                onClick={handleUploadFile}
                            />
                            <ButtonSecondary
                                text="Cancel"
                                customClass="bg-white text-dark4 text-bodyMR border border-dark4 px-[22px] py-[6px]"
                                onClick={handleCancel}
                            />
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default ModalUploadFile;
