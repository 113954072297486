import Desktop from "./screen-view/Desktop";
import Mobile from "./screen-view/Mobile";

export default function SectionOne() {
  return (
    <section className="section-one bg-white" id="section-1">
      <Desktop />
      <Mobile />
    </section>
  );
}
