import React from 'react';
import DownloadIcon from "../../icons/DownloadIcon";

const ButtonDownload = ({className}) => {
    return (
        <div>
            <button
                className={`border rounded border-dark5 ${className} w-full py-[8px] flex flex-row justify-center gap-2`}>
                <DownloadIcon className="pt-1"/>
                <p className="text-bodyMR text-dark4">Download</p>
            </button>
        </div>
    );
};

export default ButtonDownload;