import React, {useEffect, useState} from 'react';
import {settingsFields} from "../constants/formFields";
import SettingsForm from "../components/form/SettingsForm";
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import ModalConfirm from "../components/modal/modalConfirm";
import axiosConfig from "../axiosConfig";
import {toast, ToastContainer} from "react-toastify";

const SettingsPage = () => {
    const [settingsState, setSettingsState] = useState(settingsFields);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [settings, setSettings] = useState([]);

    const handleChange = (e, index) => {
        setSettingsState((prevState) => {
            const updatedState = [...prevState];
            updatedState[index]["value"] = e.target.value;
            return updatedState;
        });
    };

    const handleDiscardChangesClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    ///GET DATA FROM SETTINGS
    useEffect(() => {
        axiosConfig.get('settings/get')
            .then(response => {
                const data = response.data;
                const notificationFrequencies = data.notificationFrequencies.map(frequency => ({
                    id: frequency.id,
                    value: frequency.name,
                    label: frequency.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
                }));

                const notificationMethods = data.notificationMethods.map(method => ({
                    id: method.id,
                    value: method.name,
                    label: method.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
                }));

                const updatedSettingsState = [...settingsState];
                updatedSettingsState[0].options = notificationFrequencies;
                updatedSettingsState[1].options = notificationMethods;
                setSettingsState(updatedSettingsState);
            })
            .catch(error => {
                toast.error('Eroare la preluarea datelor', error.data);
            });
    }, []);

    //// UPDATE DATA
    const handleUpdateSettings = () => {
        const updateDataValues = {};
        settingsState.forEach(field => {
            if (field.value !== undefined) {
                updateDataValues[field.id] = field.value;
            }
        });
        axiosConfig.post('settings/update', updateDataValues)
            .then(response => {
                toast.success('Settings updated successfully');
            })
            .catch(error => {
                toast.error('Error updating settings');
            });
    };

    return (
        <div className="flex flex-col desktop:h-full">
            <div>
                <h6 className="text-h6 mobile:bg-white mobile:p-6 text-dark4">Settings</h6>
                <div className="title-subtitle-section pt-6 mobile:px-6">
                    <h6 className="text-h6 text-dark4">How to know if you died?</h6>
                    <p className="text-bodySR text-black pt-5 pb-7">Set up how many times you want to be
                        alerted.</p>
                </div>
                <div className="formular mobile:px-6">
                    <form className="mobile:flex-col">
                        <div
                            className="mobile:space-y-8 desktop:w-3/4 desktop:grid desktop:grid-cols-2 desktop:gap-4">
                            {settingsState.map((field, index) => (
                                <SettingsForm
                                    key={field.id}
                                    handleChange={(e) => handleChange(e, index)}
                                    value={field.value}
                                    labelText={field.labelText}
                                    labelFor={field.labelFor}
                                    id={field.id}
                                    name={field.name}
                                    type={field.type}
                                    isRequired={field.isRequired}
                                    customClass="rounded-lg"
                                    options={field.options}
                                />
                            ))}
                        </div>
                    </form>
                </div>
            </div>

            <div className="buttons-action mt-auto mobile:px-6">
                <div className="flex-row flex gap-2 cursor-pointer mobXS:justify-center mobile:pt-8 desktop:mb-8">
                    <ButtonSecondary text="Update"
                                     customClass="bg-primary text-white text-center justify-center"
                                     width="mobXS:w-full tabMD:w-1/4 desktop:w-[150px]"
                                     padding="px-[5px] py-[10px]" onClick={handleUpdateSettings}/>
                    <ButtonSecondary text="Discard changes"
                                     customClass="bg-white border text-dark4 text-center justify-center"
                                     width="mobXS:w-full tabMD:w-1/4 desktop:w-[150px]"
                                     padding="px-[5px] py-[10px]" onClick={handleDiscardChangesClick}/>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {isModalOpen && (
                <ModalConfirm titleModal="Are you sure you want to discard the changes?" closeModal={closeModal}/>
            )}
        </div>

    );
};

export default SettingsPage;
