import React from 'react';

const DotsIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5.25" cy="12" r="2.25" fill="#9CA3AF"/>
            <circle cx="12" cy="12" r="2.25" fill="#9CA3AF"/>
            <circle cx="18.75" cy="12" r="2.25" fill="#9CA3AF"/>
        </svg>
    );
};

export default DotsIcon;