import React from 'react';

const LogoIcon = () => {
    return (
        <svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M33.5224 0C23.0448 0 10.4187 0 0 0V8.21137H15.9813C16.1284 8.21137 16.305 8.35853 16.305 8.53512C16.305 8.74114 16.1579 8.8883 15.9813 8.8883C13.0087 8.8883 10.5953 11.3017 10.5953 14.2743V31.9331H18.8067V8.21137H33.5224V0Z"
                fill="#314CB6"/>
            <path d="M29.0488 23.6626H20.808V31.874H29.0488V23.6626Z" fill="#314CB6"/>
            <path
                d="M55.3603 0H33.4339V8.21137H35.2586V14.2743V18.5124V23.3391V23.6629H31.0499V31.8743H38.4078C41.2332 31.7565 43.5289 29.3726 43.5289 26.4883C43.5289 26.3411 43.676 26.1646 43.8526 26.1646C43.9998 26.1646 44.1764 26.3117 44.1764 26.4883C44.1764 29.4609 46.5897 31.8743 49.5034 31.9331C51.0339 31.9331 52.9175 31.9331 55.3603 31.9331C59.9222 31.9331 63.5717 28.2248 63.5717 23.7217V8.21137C63.5717 3.70836 59.9222 0 55.3603 0ZM55.3603 23.6629H43.5289C43.5289 18.689 43.5289 13.2441 43.5289 8.21137H55.3603V23.6629Z"
                fill="#314CB6"/>
        </svg>
    );
};

export default LogoIcon;