import React from 'react';

const ApproveIcon = () => {
    return (
        <div>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.8187 1.94314L16.8187 1.94321L7.21889 14.5429C7.21885 14.543 7.21882 14.543 7.21879 14.5431C7.18419 14.5884 7.14032 14.6258 7.0901 14.6527C7.03983 14.6797 6.98437 14.6956 6.92743 14.6994C6.8705 14.7032 6.81342 14.6948 6.76002 14.6747C6.70662 14.6546 6.65813 14.6233 6.61782 14.5829L6.61755 14.5827L1.22503 9.19016C1.15742 9.11498 1.12073 9.0169 1.12251 8.91563C1.12434 8.812 1.16632 8.71313 1.23961 8.63984C1.3129 8.56656 1.41177 8.52457 1.51539 8.52275C1.61667 8.52096 1.71477 8.55766 1.78996 8.62529L6.45529 13.2894L6.85983 13.6939L7.20651 13.2389L16.1825 1.45847L16.1826 1.45841C16.2468 1.37411 16.3418 1.31874 16.4468 1.30444C16.5517 1.29015 16.6581 1.31807 16.7425 1.38208C16.7844 1.41388 16.8196 1.45363 16.8461 1.49905C16.8726 1.54448 16.8899 1.59468 16.897 1.64679C16.904 1.6989 16.9008 1.7519 16.8874 1.80276C16.8739 1.85361 16.8506 1.90132 16.8187 1.94314Z"
                    stroke="white"/>
            </svg>
        </div>
    );
};

export default ApproveIcon;