import ContentText from "../elements/ContentText";
import Media from "../elements/Media";

export default function Desktop() {
  return (
    <div className="flex flex-row mobile:hidden">
      <div className="basis-8/12 lgDesktop:p-paddingSC1 xxlgDesktop:p-padSC1 xxxlgDesktop:p-padSCXL initialDesktop:p-padSC2 bg-gray2 rounded-roundedBottom150">
        <ContentText />
      </div>
      <div className="basis-2/6  xl:ml-[-65px] xl:mt-[95px] xxlgDesktop:ml-[-145px] xxlgDesktop:mt-[50px] xxxlgDesktop:mt-[50px] xxxlgDesktop:ml-[-183px] initialDesktop:mt-[45px] initialDesktop:ml-[-160px]">
        <Media rounded="rounded-roundedTop150" />
      </div>
    </div>
  );
}
