import React, {useEffect, useState} from 'react';
import EmptyFolder from "../../../icons/EmptyFolder";
import DotsIcon from "../../../icons/DotsIcon";
import axiosConfig from "../../../axiosConfig";
import {toast, ToastContainer} from "react-toastify";
import DocumentIcon from "../../../icons/DocumentIcon";
import ImageIcon from "../../../icons/ImageIcon";

const ContentFiles = () => {
    const [folderStructure, setFolderStructure] = useState({});
    const [selectedFolders, setSelectedFolders] = useState([]);


    useEffect(() => {
        axiosConfig.get('/file-manager/get-folder-structure')
            .then(response => {
                const {directoryStructure} = response.data;
                const sanitizedStructure = sanitizeDirectoryNames(directoryStructure);
                setFolderStructure(sanitizedStructure);
            })
            .catch(error => {
                toast.error('Error fetching data', error.data);
            });
    }, []);
    const sanitizeDirectoryNames = (structure) => {
        const sanitizedStructure = {};
        for (const key in structure) {
            const sanitizedKey = key.replace('/', '');
            sanitizedStructure[sanitizedKey] = structure[key];
        }
        return sanitizedStructure;
    };
    const getExtension = (fileName) => {
        const parts = fileName.split('.');
        if (parts.length >= 2) {
            const extension = parts[parts.length - 1];
            return extension.toLowerCase();
        } else {
            return '';
        }
    };
    const handleCheckboxChange = (folderName, isChecked) => {
        if (isChecked) {
            setSelectedFolders([...selectedFolders, folderName]);
        } else {
            setSelectedFolders(selectedFolders.filter(name => name !== folderName));
        }
    };

    return (
        <div className="content-files px-6 py-6 space-y-9 cursor-pointer">
            <div className="folders space-y-4">
                <h6 className="text-bodyLR text-dark4">Folders</h6>
                <div className="content-folders flex mobile:flex-col desktop:flex-row desktop:space-x-6 mobile:space-y-4 desktop:items-center">
                    {Object.entries(folderStructure).map(([folderName, folderInfo]) => (
                        folderInfo.isDirectory && (
                            <div key={folderName}
                                 className={`folder-item flex flex-row mobile:justify-between mobile:items-center`}>
                                <label
                                    className={`flex flex-row relative desktop:flex-col space-x-2 items-center desktop:border  ${selectedFolders.includes(folderName) ? 'desktop:bg-blueLight5' : 'desktop:bg-white'} desktop:rounded-lg desktop:p-4 desktop:hover:shadow-shadow5 group`}
                                    htmlFor={`folder-checkbox-${folderName}`}
                                >
                                    <input
                                        type="checkbox"
                                        id={`folder-checkbox-${folderName}`}
                                        className="rounded-lg absolute right-2 top-2 opacity-0 group-hover:opacity-100"
                                        checked={selectedFolders.includes(folderName)}
                                        onChange={(e) => handleCheckboxChange(folderName, e.target.checked)}
                                    />
                                    <div>
                                        <EmptyFolder className="mobile:hidden cursor-pointer" dimensions={{width: 90, height: 90}}/>
                                        <EmptyFolder className="desktop:hidden cursor-pointer" dimensions={{width: 34, height: 34}}/>
                                    </div>
                                    <div>
                                        <p className="text-bodySR text-dark3 desktop:text-center">{folderName}</p>
                                    </div>
                                </label>
                                <div className="desktop:hidden">
                                    <DotsIcon/>
                                </div>
                            </div>

                        )
                    ))}
                </div>
            </div>
            <div className="files space-y-4">
                <h6 className="text-bodyLR text-dark4">Files</h6>
                <div
                    className="content-files flex mobile:flex-col desktop:flex-row desktop:space-x-6 mobile:space-y-4 desktop:items-center">
                    {Object.entries(folderStructure).map(([folderName, folderInfo]) => (
                        !folderInfo.isDirectory && (
                            <div key={folderName}
                                 className="folder-item flex flex-row mobile:justify-between mobile:items-center">
                                <div
                                    className="flex flex-row desktop:flex-col space-x-2 items-center desktop:border desktop:bg-white desktop:border-white desktop:rounded-lg desktop:p-4">
                                    <div>
                                        {getExtension(folderName) === 'pdf' ? (
                                            <DocumentIcon className="mobile:hidden"
                                                          dimensions={{width: 106, height: 90}}/>
                                        ) : (
                                            <ImageIcon className="mobile:hidden" dimensions={{width: 106, height: 90}}/>
                                        )}
                                        {getExtension(folderName) === 'pdf' ? (
                                            <DocumentIcon className="desktop:hidden"
                                                          dimensions={{width: 34, height: 34}}/>
                                        ) : (
                                            <ImageIcon className="desktop:hidden" dimensions={{width: 34, height: 34}}/>
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-bodySR text-dark3 desktop:text-center">{folderName}</p>
                                    </div>
                                </div>
                                <div className="desktop:hidden">
                                    <DotsIcon/>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>

    );
};

export default ContentFiles;