import ButtonMenu from "../elements/ButtonMenu";
import LogoHeader from "../elements/LogoHeader";
import DynamicNav from "../../nav/DynamicNav";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import ButtonPrimary from "../../buttons/ButtonPrimary";

export default function Mobile() {
  const navLinks = [
    { src: null, url: "#section-2", text: "How it works" },
    { src: null, url: "#section-3", text: "Why choose us" },
    { src: null, url: "#section-5", text: "Pricing" },
    { src: null, url: "#section-6", text: "Security" },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="grid gap-2 grid-cols-3 p-5 desktop:hidden">
        <div className="flex items-start justify-start">
          <ButtonMenu toggleMenu={toggleMenu} isOpen={isOpen} />
        </div>
        <LogoHeader />
      </div>

      <div className={`bg-white  w-full h-full border p-5 ${isOpen ? "" : "hidden"}`}>
        <div className="flex flex-col gap-y-6 items-start justify-self-start">
          <DynamicNav links={navLinks} navigateTo={false}/>
          <div className="flex">
            <UserCircleIcon className="h-6 w-6 text-blue-500" />
            <a href="/login" className="text-primary">
              Login
            </a>
          </div>
          <ButtonPrimary
            text="Create account"
            action="/register"
            fontsize="text-bodyMR"
            padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
          />
        </div>
      </div>
    </>
  );
}
