import Columns from "../elements/Columns";

export default function Desktop() {
  const data = [
    {
      image: "./section-three/box11.png",
      icon: "./section-three/personalized_msg.svg",
      title: "Personalized Messages",
      text: "Share words from the heart, attach files, and make your messages uniquely personal with just a few clicks..",
    },
    {
      image: "./section-three/finger-point.png",
      icon: "./section-three/icon2.svg",
      title: "Protect Your Legacy",
      text: "Your digital treasures are kept safe, ensuring your messages are delivered according to your wishes.",
    },
    {
      image: "./section-three/iphone.png",
      icon: "./section-three/effortless.svg",
      title: "Effortless Experience",
      text: "Navigate our platform with ease, focusing on what truly matters – preserving your legacy.",
    },
  ];

  return (
    <div className="mobile:hidden ">
      <div className="xl:container xl:px-16 xxl:px-0 xxl:mx-auto pt-32">
        <div className="grid grid-cols-2">
          <h3 className="text-h3 text-dark2">Why choose Testament.digital</h3>
          <p className="text-dark5 text-bodyLSB">
            Craft your digital legacy effortlessly with{" "}
            <span className="text-bodyLS">Testament.digital</span>. Enjoy
            complete control, unlimited rules, and secure sharing. Your story,
            your way – we simplify the journey.
          </p>
        </div>
        <div className="grid grid-cols-3 lg:gap-20 initialDesktop:gap-64 xxxlgDesktop:gap-64 pt-16">
          {data.map((item, index) => (
            <Columns
              key={index}
              image={item.image}
              icon={item.icon}
              title={item.title}
              text={item.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
