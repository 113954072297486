import React from 'react';
import EditMessageIcon from "../../icons/EditMessageIcon";

const ButtonEdit = ({textButton, className}) => {
    return (
        <div className="flex flex-row  items-center space-x-2 cursor-pointer">
            <EditMessageIcon />
            <button className={`${className}`} onClick="">{textButton}</button>
        </div>
    );
};

export default ButtonEdit;