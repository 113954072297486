export default function SlideComponent({ stepTxt, info, image, description }) {
  return (
    <>
      <div className="slide desktop:hidden">
        <p className="text-primary text-bodyMM text-center">{stepTxt}</p>
        <p className="text-dark5 text-bodyLS text-center">{info}</p>
        <div className="flex items-center justify-center py-6">
          <img
            src={image}
            alt="slide-image"
            className="mobXS:w-[325px] mobXS:h-[237px] tabMD:w-4/6 mobile:px-6"
          />
        </div>
        <ul className="text-dark5 text-bodyLR pb-9 mobile:px-12">
          {description.map((item, index) => (
            <li key={index} style={{ listStyleType: "circle" }}>
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div className="slide mobile:hidden">
        <div className="grid grid-cols-2">
          <div className="pt-24 ">
            <p className="text-primary text-bodyLM text-start">{stepTxt}</p>
            <h5 className="text-dark5 text-h5 text-start">{info}</h5>
            <ul className="text-dark5 text-bodyLR px-5 pt-9 pb-36 mobile:px-12">
              {description.map((item, index) => (
                <li key={index} style={{ listStyleType: "circle" }}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-2">
            <div className="flex items-end justify-end py-6">
              <img
                src={image}
                alt="slide-image"
                className="xl:w-[481px] xl:h-[351px] mobile:px-6"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
