export default function Mobile() {
  return (
    <div className="mobXS:container mobXS:mx-auto mobile:px-6 mobXS:py-14 tabMD:pt-44 desktop:hidden">
      <div className="title-subtitle">
        <h4 className="text-dark3 text-center text-h4">
          How do we know you've passed away?
        </h4>
        <p className="text-bodyLR text-dark5 text-center py-9">
          Rely on us as your committed digital courier, ensuring the precise
          delivery of your messages and executing your digital testament just as
          you've planned.
        </p>
      </div>
      <div className="cards space-y-6">
        <div className="card bg-bg1 border border-gary1 rounded-3xl p-6 relative">
          <img
            src="./section-four/rectangle.svg"
            alt="arrow"
            className="absolute left-0 mobXS:bottom-[50px] tabMD:bottom-[35px]"
          />
          <p className="text-bodyMM text-dark5">
            Decide the pace of emails we will send over a specified number of
            days to verify if you are alive.
          </p>
        </div>
        <div className="card bg-bg1 border border-gary1 rounded-3xl p-6 relative">
          <img
            src="./section-four/rectangle.svg"
            alt="arrow"
            className="absolute left-0 mobXS:bottom-[65px] tabMD:bottom-[35px]"
          />
          <p className="text-bodyMM text-dark5">
            If left unverified, our system intelligently triggers the delivery
            of all pending messages, ensuring your legacy is shared as planned.
          </p>
        </div>
        <img
          src="./section-four/young-woman.png"
          alt="young-woman"
          className="rounded-lg tabMD:w-full tabMD:h-full"
        />
      </div>
    </div>
  );
}
