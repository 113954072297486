import React from 'react';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LoginPage from "../pages/LoginPage";
import {Navigate, Route, Routes} from "react-router-dom";
import RegisterPage from "../pages/RegisterPage";
import HomePage from "../pages/HomePage";
import Cookies from "js-cookie";
import MyMessagesPage from "../pages/MyMessagesPage";
import BillingPlanPage from "../pages/BillingPlanPage";
const PublicLayout = () => {
    const accessToken = Cookies.get('AccessToken');

    if (accessToken) {
        return <Navigate to="/dashboard"/>
    }
    return (
        <div className="page bg-gary1">
            <Header/>
            <Routes>
                <Route path="*" element={<HomePage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
            </Routes>
            <Footer />
        </div>
    );
};

export default PublicLayout;