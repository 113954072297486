import React, {useEffect, useState} from 'react';
import SortBy from "./SortBy";
import ButtonApprove from "../../buttons/ButtonApprove";
import ButtonAttach from "../../buttons/ButtonAttach";
import ModalConfirm from "../../modal/modalConfirm";
import {useLocation} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import axiosConfig from "../../../axiosConfig";
import ButtonEdit from "../../buttons/ButtonEdit";
import {initTWE, Tab} from "tw-elements";

const ContentMessages = () => {
    const [isNoMessageVisible, setIsNoMessageVisible] = useState(true);
    const [isTestDivVisible, setIsTestDivVisible] = useState(false);
    const location = useLocation();
    const currentPage = location.pathname.split('/').pop();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);

    useEffect(() => {
        initTWE({Tab});
    }, []);

    const handleDiscardChangesClick = () => {
        setIsModalOpen(true);
    };

    const handleTabSelect = (messageId) => {
        setSelectedTab(messageId);
    };
    const handleMailSelect = () => {
        setIsNoMessageVisible(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const toggleTestDiv = () => {
        setIsTestDivVisible(!isTestDivVisible);
    };

    useEffect(() => {
        axiosConfig.get('/outbox/get-messages')
            .then(response => {
                const fetchedMessages = response.data.messages;
                setMessages(fetchedMessages);
            })
            .catch(error => {
                toast.error('Error fetching data', error.data);
            });
    }, []);

    const extractInitials = (name) => {
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase());
        return initials.join('');
    };

    const formatMessageBody = (body) => {
        const plainText = body.replace(/<[^>]+>/g, '');
        return plainText.length > 30 ? plainText.substring(0, 30) + '...' : plainText;
    };
    const escapeHTML = (body) =>{
        return body.replace(/<[^>]+>/g, '');
    }

    return (
        <div className="desktop:flex desktop:flex-row gap-6 desktop:h-full">
            <div
                className="card-mail-preview desktop:w-[40%] cursor-pointer desktop:rounded-bl-2xl desktop:rounded-br-2xl">
                <div
                    className="tools-bar flex flex-row p-6  bg-white  desktop:rounded-tl-2xl desktop:rounded-tr-2xl justify-between mobile:hidden">
                    <p className="text=bodyMR text-black">{'My Message'}</p>
                    <SortBy className="mobile:hidden" items={['Date', 'Recipient name', 'Subject']}/>
                </div>
                {/*left messages tab*/}
                <div className="mails bg-white overflow-auto desktop:h-[76%] desktop:rounded-bl-2xl desktop:rounded-br-2xl"
                    onClick={handleMailSelect} role="tablist">
                    {messages.map(message => (
                        <a key={message.id}
                           className={`mail items-center flex flex-row gap-3 py-6 px-6 hover:bg-gary1 ${selectedTab === message.id ? 'data-[twe-tab-active]' : ''}`}
                           href={`#tab-${message.id}`}
                           data-twe-toggle="pill"
                           data-twe-target={`#tab-${message.id}`}
                           aria-controls={`tab-${message.id}`}
                           aria-selected={selectedTab === message.id ? "true" : "false"}
                           role="tab"
                           onClick={() => handleTabSelect(message.id)}>
                            <div>
                                <h6 className="border w-[45px] text-center bg-primary text-white p-2 rounded-full">{extractInitials(message.label)}</h6>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <p className="text-bodyMR text-dark2">{message.label}</p>
                                <div className="flex flex-row gap-4">
                                    <p className="hidden text-redDark text-bodyESR bg-redLight p-1 text-center rounded-3xl w-[45px]">Draft</p>
                                    <p className="text-bodySM text-dark4">{message.subject}</p>
                                </div>
                                <p className="text-bodyESR text-dark5">{formatMessageBody(message.body)}</p>
                            </div>
                            <img src="/pinGreen.svg" alt="" className="hidden w-[16px] ml-auto mb-auto"/>
                        </a>
                    ))}
                </div>
            </div>
            {/*right messages content*/}
            <div className="preview-message desktop:w-[60%] desktop:h-[85%] bg-white rounded-2xl mobile:hidden">
                {isNoMessageVisible ? (
                    <div className="no-message p-9 ">
                        <div className="flex flex-col items-center">
                            <div>
                                <img src="/envelopes.svg" className="" alt=""/>
                            </div>
                            <h6 className="text-dark4 text-h6">No message selected</h6>
                            <p className="test-bodySR text-dark6">Select or create a message</p>
                        </div>
                    </div>
                ) : (
                    <div className={`message-selected flex flex-col h-full  p-9`}>
                        {messages.map(message => (
                            <div key={message.id}
                                 id={`tab-${message.id}`}
                                 className={`message-content ${selectedTab === message.id ? 'block' : 'hidden'} opacity-100 transition-opacity duration-150 ease-linear`}
                                 role="tabpanel"
                                 aria-labelledby={`tabs-${message.id}`}>
                                <div className="header space-y-6">
                                    <div className="header-sb-mail flex flex-row justify-between">
                                        <p className="text-bodyLM text-black">{message.label}</p>
                                        <ButtonEdit textButton="Edit Message" className="text-primary"/>
                                    </div>
                                    <div className="header-to-mail border-b-2 flex flex-row space-x-10">
                                        <p className="text-bodySR text-black">To:</p>
                                        <p className="email-address text-bodySR text-dark5">{message.recipient_email}</p>
                                    </div>
                                </div>
                                <div className="content-mail pt-6">
                                    <p className="text-bodSR text-black">{escapeHTML(message.body)}</p>
                                </div>
                            </div>
                        ))}
                        <div className="attachments">
                            {/* Aici puteți adăuga componente pentru atașamente, dacă este cazul */}
                        </div>
                        <div className="footer hidden flex-row justify-between mt-auto">
                            <div className="flex flex-row gap-5">
                                <ButtonApprove/>
                                <ButtonAttach/>
                            </div>
                            <div className="flex items-center">
                                <button className="text-dark4 text-bodyMM" onClick={handleDiscardChangesClick}>Discard
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className={isTestDivVisible ? 'test' : 'hidden'}>
                    TEst
                </div>
                {isModalOpen && <ModalConfirm titleModal="Are you sure you want to discard this draft?"
                                              textModal="This action is irreversible." closeModal={closeModal}/>}
            </div>
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default ContentMessages;
