import Axios from 'axios';

const { REACT_APP_API_BASE_URL } = process.env;

const axiosConfig = Axios.create({
    baseURL: `${REACT_APP_API_BASE_URL}`,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});

export default axiosConfig;
