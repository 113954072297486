import Mobile from "./screen-view/Mobile";
import Desktop from "./screen-view/Desktop";

export default function Footer() {
  return (
    <footer className="footer bg-white">
      <Desktop />
      <Mobile />
    </footer>
  );
}
