import React, { useState } from 'react';
import ButtonAddNew from "../../buttons/ButtonAddNew";
import SortBy from "./SortBy";
import Search from "../header/Search";
import ModalCreateFolder from "../../modal/modalCreateFolder";
import ButtonDelete from "../../buttons/ButtonDelete";
import ButtonDownload from "../../buttons/ButtonDownload";
import ItemsSelected from "../../buttons/ItemsSelected";
import ModalUploadFile from "../../modal/modalUploadFile";

const NavigationMyFiles = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsVisible(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCloseConfirmModal = () => {
        setIsConfirmOpen(false);
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const handleDeleteButtonClick = () => {
        setIsConfirmOpen(true);
    };

    const handleUploadModalOpen = () => {
        setIsUploadModalOpen(true);
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false);
    };


    return (
        <div>
            <div className="title-and-button mobile:bg-white space-y-6 py-6">
                <div className="flex flex-row justify-between px-6 items-center">
                    <div>
                        <h6 className="text-dark4 text-h6">My files</h6>
                    </div>
                    <div className={`buttons-action flex flex-row space-x-12 mobile:hidden`}>
                        <ButtonDownload className="border-0"/>
                        <ButtonDelete className="border-0" deleteAction={handleDeleteButtonClick}/>
                        <ItemsSelected className="rounded-xl hidden"/>
                    </div>
                    <div className="flex flex-row space-x-6">
                        <SortBy textSort="SortBy" className="mobile:hidden"/>
                        <div className="relative cursor-pointer">
                            <ButtonAddNew textButton="Add new" onClick={toggleVisibility}/>
                            {isVisible && (
                                <div
                                    className="bg-white border cursor-pointer absolute w-[200px] right-[0px] rounded-lg mt-2 z-20">
                                    <p className="text-bodyMR text-dark4 hover:text-white hover:bg-primary px-2 py-2"
                                       onClick={handleOpenModal}>New folder</p>
                                    <p className="text-bodyMR text-dark4 hover:text-white hover:bg-primary px-2 py-2" onClick={handleUploadModalOpen}>New file</p>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
                <div className="flex flex-row justify-between px-6 items-center desktop:hidden">
                    <div>
                        <Search/>
                    </div>
                    <div>
                        <SortBy textSort="SortBy" className="desktop:hidden"/>
                    </div>
                </div>
                <div className={`buttons-action flex flex-row px-3 items-center space-x-6 desktop:hidden`}>
                    <ButtonDownload className="border-0"/>
                    <ButtonDelete className="border-0" deleteAction={handleDeleteButtonClick}/>
                    <ItemsSelected className="rounded-xl"/>
                </div>
            </div>
            <ModalCreateFolder isOpen={isModalOpen} onClose={handleCloseModal}/>
            <ModalUploadFile isOpen={isUploadModalOpen} onClose={handleUploadModalClose}/>
        </div>
    );
};

export default NavigationMyFiles;
