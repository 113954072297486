import Mobile from "./screen-view/Mobile";
import Desktop from "./screen-view/Desktop";

export default function SectionFour() {
  return (
    <section className="section-four bg-white" id="section-4">
      <Mobile />
      <Desktop />
    </section>
  );
}
