export default function Columns({ image, icon, title, text }) {
  return (
    <div className="element relative flex items-start justify-start">
      <img
        src={image}
        alt="Imagine"
        className="rounded-lg w-[280px] h-[288px]"
      />
      <div className="absolute bg-gary1 border-gary1 border rounded-lg p-4 w-[220px] h-[250px] left-1/3 top-1/2 shadow-shadow5">
        <img alt="icon" src={icon} className="w-[41px] h-[41px]" />
        <div className="tabMD:py-5">
          <h2 className="text-primary desktop:text-bodyLB pr-16 py-2">
            {title}
          </h2>
          <p className="desktop:text-bodyESR py-2 text-dark5">{text}</p>
        </div>
      </div>
    </div>
  );
}
