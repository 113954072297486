import FooterElements from "../elements/FooterElements";

export default function Mobile() {
  const sections = [
    {
      title: "Contact",
      elements: [
        { text: "+ 123-456-7890" },
        { text: "contact@testament.digital" },
        { text: "Mon – Sun: 10am – 10pm" },
      ],
    },
    {
      title: "Useful links",
      elements: [
        { text: "Privacy Policy", url: "/##" },
        { text: "Terms and Conditions", url: "/###" },
        { text: "Cookies", url: "/####" },
      ],
    },
    {
      title: "Follow us",
      elements: [
        { text: "Facebook", url: "/#####" },
        { text: "Linkedin", url: "/#######" },
        { text: "Twitter", url: "/#########" },
      ],
    },
  ];
  return (
    <>
      <div className="grid grid-flow-row space-y-12 auto-rows-max pt-14 pb-14 tabMD:hidden desktop:hidden">
        {sections.map((section, index) => (
          <div key={index} className="">
            <FooterElements title={section.title} elements={section.elements} />
          </div>
        ))}
      </div>

      <div className="grid grid-cols-3 px-6 py-20 mobXS:hidden desktop:hidden">
        {sections.map((section, index) => (
          <div key={index} className="">
            <FooterElements title={section.title} elements={section.elements} />
          </div>
        ))}
      </div>
    </>
  );
}
