import React, { useState } from 'react';

const ToggleButton = () => {
    const [isActive, setIsActive] = useState(false);

    const toggle = () => {
        setIsActive(!isActive);
    };

    return (
        <div className={`toggle-button relative w-12 h-6 rounded-full bg-gray4 ${isActive ? 'bg-primary' : ''} cursor-pointer`} onClick={toggle}>
            <div className={`slider w-6 h-6 bg-white rounded-full absolute top-0 left-0 transition-transform transform ${isActive ? 'translate-x-6' : 'translate-x-0'}`}></div>
        </div>
    );
};

export default ToggleButton;
