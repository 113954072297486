import React, { useState } from 'react';
import axios from 'axios';
import ButtonSecondary from "../buttons/ButtonSecondary";
import axiosConfig from "../../axiosConfig";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";

const ModalCreateFolder = ({ isOpen, onClose }) => {
    const [folderName, setFolderName] = useState('');
    const [folderCreated, setFolderCreated] = useState(false);

    const handleInputChange = (e) => {
        setFolderName(e.target.value);
    };

    const handleCreateFolder = async () => {
        const accessToken = Cookies.get('AccessToken');
        const pathToFolder = "/";

        try {
            if (!accessToken) {
                return;
            }

            const config = {
                withCredentials: true,
            };

            await axios.post(
                `http://localhost:80/api/actions/file-manager/create-folder`,
                {
                    folderName,
                    pathToFolder
                },
                config
            );

            toast.success('Folder created successfully');
            setFolderCreated(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            toast.error('Error creating folder:', error);
        }
    };

    const handleCancel = () => {
        onClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center z-50"
        >
            <div className={`${folderCreated ? 'bg-greenLight' : 'bg-white'} p-8 rounded-lg mx-6`}>
                {folderCreated ? (
                    <div>
                        <p className="text-bodyMR text-dark4 mb-4 text-left"><span className="text-bodyMS text-greenDark">{folderName} </span>has been created with success</p>
                    </div>
                ) : (
                    <div>
                        <h2 className="text-h6 text-dark4 mb-4 text-left">Create a folder</h2>
                        <label htmlFor="folderName" className="text-bodyLR text-dark4 text-left">Name</label>
                        <input
                            type="text"
                            id="folderName"
                            className="border-gray-300 border p-2 w-full rounded mt-2"
                            value={folderName}
                            onChange={handleInputChange}
                        />
                        <div className="flex justify-center mt-4 space-x-6">
                            <ButtonSecondary
                                text="Create"
                                customClass="bg-primary text-white text-bodyMR p-1 px-[22px] py-[6px]"
                                onClick={handleCreateFolder}
                            />
                            <ButtonSecondary
                                text="Cancel"
                                customClass="bg-white text-dark4 text-bodyMR border border-dark4 px-[22px] py-[6px]"
                                onClick={handleCancel}
                            />
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default ModalCreateFolder;
