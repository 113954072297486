import ButtonPrimary from "../../../buttons/ButtonPrimary";

export default function Mobile() {
  return (
    <div className="flex flex-col py-16 desktop:hidden">
      <img
        className="mx-auto justify-center w-[76px]"
        src="/aws.svg"
        alt="aws"
      />
      <div>
        <h4 className="text-h4 text-dark3 text-center py-10">
          Secure Hosting on Amazon
        </h4>
        <p className="text-dark5 text-bodyLSB text-center">
          Your data is housed securely on Amazon Web Services (AWS) because the
          security of your digital legacy is our top priority.
        </p>
        <p className="text-dark5 text-bodyLSB pt-5 text-center">
          Your legacy, your rules. Start crafting it today with{" "}
          <span className="text-primary text-bodyLR">Testament.digital</span>.
        </p>
      </div>
      <div className="tabMD:items-center tabMD:justify-center tabMD:flex py-10">
        <ButtonPrimary
          text="Get Started Now"
          width="mobXS:w-full"
          padding="mobXS:py-[14px] mobXS:px-[24px] tabMD:py-[14px] tabMD:px-[24px]"
          fontsize="text-bodyLR"
        />
      </div>
      <div className="tabMD:flex tabMD:items-center tabMD:justify-center">
        <img src="/family.png" alt="family" className="tabMD:w-1/2" />
      </div>
    </div>
  );
}
