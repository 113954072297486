import Desktop from "./screen-view/Desktop";
import Mobile from "./screen-view/Mobile";

export default function SectionThree() {
  return (
    <section className="section-three bg-white" id="section-3">
      <Desktop />
      <Mobile />
    </section>
  );
}
