import React from 'react';
import {useNavigate} from "react-router-dom";

const Search = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        document.cookie = 'AccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        navigate('/login');
    };
    return (
        <div className="flex flex-row  items-center desktop:p-10">
            <div className="desktop:basis-3/4">
                <form className="desktop:pr-9">
                    <div className="relative">
                        <div
                            className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <img className="" src="/search.svg" alt="search"/>
                        </div>
                        <input type="search" id="default-search"
                               className="block w-full p-4 ps-10 text-sm text-dark5 border border-stroke rounded-lg bg-white"
                               placeholder="Type to search" required/>
                    </div>
                </form>

            </div>
            <div className="basis-1/4 flex justify-end text-end cursor-pointer mobile:hidden">
                <img src="/mini.svg"/>
                <a className="text-primary text-bodyMM pl-4" onClick={handleLogout}>Log out</a>
            </div>
        </div>
    );
};

export default Search;