import React, { useState } from 'react';

const CheckboxWithLabel = ({ label }) => {
    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div className="flex items-center space-x-2">
            <input
                type="checkbox"
                className="h-5 w-5 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
                checked={isChecked}
                onChange={toggleCheckbox}
            />
            <label className="text-black2 cursor-pointer text-bodySR">{label}</label>
        </div>
    );
};

export default CheckboxWithLabel;
