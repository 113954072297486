import React from 'react';
import AttachIcon from "../../icons/AttachIcon";

const ButtonAttach = ({className}) => {
    return (
            <div>
                <button
                    className={`border rounded border-dark6 bg-white ${className} w-[120px] py-[8px] flex flex-row justify-center items-center space-x-2`}>
                    <AttachIcon />
                    <p className="text-dark4 text-center text-bodyMR">Attach file</p>
                </button>
            </div>
    );
};

export default ButtonAttach;