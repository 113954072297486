import ButtonPrimary from "../../../buttons/ButtonPrimary";

export default function Desktop() {
  return (
    <div className="xl:container xl:px-16 xxl:px-0 xxl:mx-auto py-24 mobile:hidden">
      <div className="grid grid-cols-2">
        <div className="flex flex-col">
          <img src="/aws.svg" alt="aws" className="w-[76px]" />
          <h3 className="text-h3 text-dark2 pt-5 pb-5 pr-60">
            Secure Hosting on Amazon
          </h3>
          <p className="text-dark5 text-bodyLSB text-start">
            Your data is housed securely on Amazon Web Services (AWS) because
            the security of your digital legacy is our top priority.
          </p>
          <p className="text-dark5 text-bodyLSB pt-5 text-start">
            Your legacy, your rules. Start crafting it today with{" "}
            <span className="text-primary text-bodyLR">Testament.digital</span>.
          </p>
          <div className="pt-8">
            <ButtonPrimary
              text="Get Started Now"
              padding="px-[38px] py-[14px]"
              fontsize="text-bodyLR"
            />
          </div>
        </div>
        <div className="flex flex-row items-end justify-end">
          <img src="/family.png" alt="family" className="desktop:w-2/3" />
        </div>
      </div>
    </div>
  );
}
