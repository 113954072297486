import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Cookies from "js-cookie";
import MyFilesPage from "../pages/MyFilesPage";
import SettingsPage from "../pages/SettingsPage";
import BillingInfo from "../pages/BillingInfo";
import MessagesPage from "../pages/MessagesPage";
import HeaderDashboard from "../components/dashboard/header/HeaderDashboard";
import MyCards from "../pages/MyCards";
import Search from "../components/dashboard/header/Search";
import MyMessagesPage from "../pages/MyMessagesPage";
import BillingPlanPage from "../pages/BillingPlanPage";
import CheckoutPage from "../pages/CheckoutPage";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import SuccessPage from "../pages/SuccessPage";

const {REACT_APP_STRIPE_KEY} = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
const ProtectedLayout = () => {
    const accessToken = Cookies.get('AccessToken');

    if (!accessToken) {
        return <Navigate to="/login"/>;
    }

    return (
        <>
            <div>
                <div className="flex mobile:flex-col">
                    <HeaderDashboard/>
                    <div className="right-side-desktop h-screen bg-gray2 w-full flex flex-col">
                        <div className="search bg-white mobile:hidden">
                            <Search/>
                        </div>

                        <div className="content-page desktop:px-9 desktop:pt-9 desktop:h-full overflow-hidden">
                            <Routes>
                                <Route path="" element={<MessagesPage/>}/>
                                <Route path="my-files" element={<MyFilesPage/>}/>
                                <Route path="settings" element={<SettingsPage/>}/>
                                <Route path="billing" element={<BillingInfo/>}/>
                                <Route path="billing/my-cards" element={<MyCards/>}/>
                                <Route path="billing/plans" element={<BillingPlanPage/>}/>
                                <Route
                                    path="billing/plans/checkout/:planId"
                                    element={
                                        <Elements stripe={stripePromise}>
                                            <CheckoutPage/>
                                        </Elements>
                                    }
                                /> <Route path="/my-messages" element={<MyMessagesPage/>}/>
                                <Route path="success" element={<SuccessPage/>}/>

                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProtectedLayout;
