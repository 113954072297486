const fixedInputClass = "appearance-none block mobXS:w-full tabMD:w-1/2 justify-center py-2 mt-2 border border-stroke rounded-lg placeholder-dark6 text-dark1 focus:outline-none focus:stroke focus:stroke focus:z-10 sm:text-sm";

export default function SettingsForm({
                                         handleChange,
                                         value,
                                         labelText,
                                         labelFor,
                                         id,
                                         type,
                                         name,
                                         isRequired = false,
                                         customClass,
                                         options
                                     }) {
    return (
        <div className="w-full">
            <label htmlFor={labelFor} className="text-dark1 text-bodyMM ml-2">
                {labelText}
            </label>
            {type === 'number' ? (
                <input
                    onChange={handleChange}
                    value={value}
                    id={id}
                    name={name}
                    type={type}
                    required={isRequired}
                    className={`${fixedInputClass} ${customClass}`}
                />
            ) : (
                <select
                    onChange={handleChange}
                    value={value}
                    id={id}
                    name={name}
                    required={isRequired}
                    className={`${fixedInputClass} ${customClass}`}
                >
                    {options.map((option, index) => (
                        <option id={option.id} value={option.value}
                                selected={option.value === value}>{option.label}</option>
                    ))}
                </select>
            )}
        </div>
    );
}
