import Desktop from "./screen-view/Desktop";
import Mobile from "./screen-view/Mobile";

export default function SectionSix() {
  return (
    <section className="section-six mobile:px-6 bg-gray2" id="section-6">
      <Desktop />
      <Mobile />
    </section>
  );
}
