import { useNavigate } from "react-router-dom";
export default function ButtonPrimary({
  text,
  action,
  width,
  padding,
  fontsize,
}) {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(action);
  };
  return (
    <button
      className={`border ${width} rounded-md bg-primary color-white ${padding}`}
      onClick={handleButtonClick}
    >
      <p className={`text-white ${fontsize}`}>{text}</p>
    </button>
  );
}
