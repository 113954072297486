export default function Mobile() {
  return (
    <>
      <div className="mobXS:container mobXS:mx-auto mobile:px-6 py-14 desktop:hidden">
        <div className="info">
          <h4 className="text-dark3 text-center text-h4">
            Why choose Testament.digital
          </h4>
          <p className="text-center text-dark5 text-bodyLSB py-8">
            Craft your digital legacy effortlessly with
            <span className="text-bodyLS">Testament.digital</span>. Enjoy
            complete control, unlimited rules, and secure sharing.Your story,
            your way – we simplify the journey.
          </p>

          <div className="mobXS:space-y-32 tabMD:space-y-56">
            <div className="element relative flex items-start justify-start">
              <img
                src="./section-three/box11.png"
                alt="Imagine"
                className="rounded-lg h-full mobXS:w-3/4 tabMD:w-2/3"
              />
              <div className="absolute bg-gary1 border-gary1 border rounded-lg p-4 mobXS:w-3/4 mobXS:top-2/4  mobXS:left-1/4 tabMD:w-2/3  tabMD:top-3/4 tabMD:h-1/2 tabMD:left-1/3 shadow-shadow5">
                <img
                  alt="icon"
                  src="./section-three/personalized_msg.svg"
                  className="w-[41px] h-[41px]"
                />
                <div className="tabMD:py-5">
                  <h2 className="text-primary text-bodyLB pr-16 py-2">
                    Personalized Messages
                  </h2>
                  <p className="mobXS:text-bodyESR tabMD:text-bodyMM  tabMD:py-2 text-dark5">
                    Share words from the heart, attach files, and make your
                    messages uniquely personal with just a few clicks..
                  </p>
                </div>
              </div>
            </div>

            <div className="element relative flex items-end  justify-end">
              <img
                src="./section-three/finger-point.png"
                alt="Imagine"
                className="rounded-lg  h-full mobXS:w-3/4 tabMD:w-2/3"
              />
              <div className="absolute bg-gary1 border-gary1 border rounded-lg p-4 mobXS:w-3/4 mobXS:top-2/4  mobXS:right-1/4 tabMD:w-2/3  tabMD:top-3/4 tabMD:h-1/2 tabMD:right-1/3 shadow-shadow5">
                <img
                  src="./section-three/icon2.svg"
                  alt="icon"
                  className="w-[41px] h-[41px]"
                />
                <div className="tabMD:py-5">
                  <h2 className="text-primary text-bodyLB pr-16 py-2">
                    Protect Your Legacy
                  </h2>
                  <p className="mobXS:text-bodyESR tabMD:text-bodyMM  tabMD:py-2 text-dark5">
                    Your digital treasures are kept safe, ensuring your messages
                    are delivered according to your wishes......
                  </p>
                </div>
              </div>
            </div>

            <div className="element relative flex iitems-start  justify-start">
              <img
                src="./section-three/iphone.png"
                alt="Imagine"
                className="rounded-lg  h-full mobXS:w-3/4 tabMD:w-2/3"
              />
              <div className="absolute bg-gary1 border-gary1 border rounded-lg p-4 mobXS:w-3/4 mobXS:top-2/4  mobXS:left-1/4 tabMD:w-2/3  tabMD:top-3/4 tabMD:h-1/2 tabMD:left-1/3 shadow-shadow5">
                <img
                  alt="icon"
                  src="/iconEx.svg"
                  className="w-[41px] h-[41px]"
                />
                <div className="tabMD:py-5">
                  <h2 className="text-primary text-bodyLB pr-16 py-2">
                    Effortless Experience
                  </h2>
                  <p className="mobXS:text-bodyESR tabMD:text-bodyMM  tabMD:py-2 text-dark5">
                    Navigate our platform with ease, focusing on what truly
                    matters – preserving your legacy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
