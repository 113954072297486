import React from 'react';

const DownloadIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2365 13.98L12.1 14.8944V13.6367V3.2999C12.1 3.19382 12.1421 3.09207 12.2171 3.01706C12.2921 2.94204 12.3939 2.8999 12.5 2.8999C12.6061 2.8999 12.7078 2.94204 12.7828 3.01706C12.8578 3.09207 12.9 3.19382 12.9 3.2999V13.6367V14.8944L13.7635 13.98L17.3051 10.2299C17.3782 10.1572 17.4766 10.1156 17.5798 10.1138C17.6844 10.1119 17.7856 10.1511 17.8617 10.223C17.9377 10.2948 17.9826 10.3936 17.9866 10.4982C17.9906 10.6013 17.9546 10.702 17.8862 10.7791L12.7906 16.1744C12.7533 16.214 12.7082 16.2455 12.6582 16.267C12.6082 16.2885 12.5544 16.2996 12.5 16.2996C12.4456 16.2996 12.3917 16.2885 12.3417 16.267C12.2917 16.2455 12.2467 16.214 12.2093 16.1744L7.10948 10.7746L7.10956 10.7745L7.10206 10.7669C7.06469 10.729 7.03526 10.684 7.0155 10.6346C6.99575 10.5851 6.98606 10.5322 6.987 10.479C6.98795 10.4258 6.99951 10.3733 7.02101 10.3246C7.04251 10.2759 7.07351 10.2319 7.11221 10.1954C7.1509 10.1588 7.19651 10.1303 7.24635 10.1116C7.2962 10.0929 7.34927 10.0843 7.40247 10.0864C7.45567 10.0885 7.50792 10.1011 7.55616 10.1237C7.6044 10.1462 7.64766 10.1781 7.6834 10.2176L7.68332 10.2176L7.69045 10.2252L11.2365 13.98Z"
                stroke="#4B5563"/>
            <path
                d="M4.70002 15.2999C4.70002 15.0612 4.6052 14.8323 4.43642 14.6635C4.26764 14.4947 4.03872 14.3999 3.80002 14.3999C3.56133 14.3999 3.33241 14.4947 3.16363 14.6635C2.99485 14.8323 2.90002 15.0612 2.90002 15.2999V18.2999C2.90002 19.1751 3.2477 20.0145 3.86657 20.6334C4.48544 21.2522 5.32481 21.5999 6.20002 21.5999H18.8C19.6752 21.5999 20.5146 21.2522 21.1335 20.6334C21.7523 20.0145 22.1 19.1751 22.1 18.2999V15.2999C22.1 15.0612 22.0052 14.8323 21.8364 14.6635C21.6676 14.4947 21.4387 14.3999 21.2 14.3999C20.9613 14.3999 20.7324 14.4947 20.5636 14.6635C20.3948 14.8323 20.3 15.0612 20.3 15.2999V18.2999C20.3 19.1279 19.628 19.7999 18.8 19.7999H6.20002C5.37202 19.7999 4.70002 19.1279 4.70002 18.2999V15.2999Z"
                fill="#4B5563"/>
        </svg>
    );
};

export default DownloadIcon;