import React from 'react';

const ImageIcon = ({dimensions, className}) => {
    const { width, height } = dimensions;
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 106 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M91.2497 9.91846H14.7502C11.0253 9.91846 7.99997 12.9438 7.99997 16.6687V72.8288V73.3215C7.99997 74.573 8.34487 75.7457 8.93613 76.7508C10.1088 78.7414 12.2768 80.0816 14.7502 80.0816H91.2497C93.7232 80.0816 95.8911 78.7414 97.0638 76.7508C97.6058 75.8344 97.9408 74.7701 97.9901 73.6467C98 73.5383 98 73.4299 98 73.3314V16.6786C98 12.9437 94.9648 9.91846 91.2497 9.91846ZM72.9305 47.3355C71.2848 45.611 68.5157 45.5125 66.7517 47.1187L57.0156 56.0271L41.8892 37.0081C41.0318 35.934 39.7508 35.323 38.381 35.3427C37.0014 35.3625 35.74 36.003 34.9123 37.0968L12.0895 67.3892V16.6687C12.0895 15.2103 13.2819 14.0179 14.7404 14.0179H91.2497C92.7082 14.0179 93.9005 15.2103 93.9005 16.6687V69.3403L72.9305 47.3355Z"
                fill="#CED4DA"/>
            <path
                d="M74.8816 24.6509C70.8314 24.6509 67.5302 27.9521 67.5302 32.0022C67.5302 36.0524 70.8314 39.3536 74.8816 39.3536C78.9317 39.3536 82.233 36.0524 82.233 32.0022C82.2231 27.9422 78.9317 24.6509 74.8816 24.6509Z"
                fill="#CED4DA"/>
        </svg>
    );
};

export default ImageIcon;