import Media from "../elements/Media";
import ContentText from "../elements/ContentText";

export default function Mobile() {
  return (
    <div className="desktop:hidden bg-gray2">
      <Media rounded="rounded-roundedRightBottom150" />
      <ContentText />
    </div>
  );
}
