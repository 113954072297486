import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Input from "../components/form/Input";
import {ToastContainer, toast} from 'react-toastify';
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import 'react-toastify/dist/ReactToastify.css';
import {loginFields} from "../constants/formFields";
import {useAuth} from "../contexts/AuthProvider";
import Cookies from "js-cookie";

const LoginPage = () => {
    const [rememberMe, setRememberMe] = useState(false);
    const [loginState, setLoginState] = useState(loginFields);
    const navigate = useNavigate();
    const {accessToken } = useAuth();


    const handleChange = (e, index) => {
        setLoginState((prevState) => {
            const updatedState = [...prevState];
            updatedState[index]["value"] = e.target.value;
            return updatedState;
        });
    };

    // login user
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await accessToken();
            if (response.status === 200) {
                const { accessToken } = response.data;
                Cookies.set('AccessToken', accessToken, { expires: 1 / 24 });
                navigate('/dashboard');
            }

        } catch (error) {
            if (error.response && error.response.status === 422) {
                const errors = Object.values(error.response.data.errors);
                errors.forEach(error => {
                    toast.error(error[0]);
                });
            }
            else {
                toast.error(error.response.data.error);
                console.error('Eroare:', error);
            }
        }
    };


    const handleCheckboxChange = (e) => {
        setRememberMe(e.target.checked);
    };



    return (
        <div
            className="card-login bg-white rounded-lg mobXS:mx-6 tabMD:mx-6 my-10 border desktop:w-1/4 desktop:mx-auto">
            <h6 className="text-h6 text-dark4 text-center py-12 px-8">
                Log in to your account
            </h6>
            <form className="space-y-6" onSubmit={handleLogin}>
                <div className="space-y-px desktop:w-full flex justify-center">
                    <div className="mobXS:w-full tabMD:w-1/2 desktop:w-full tabMD:items-center">
                        {loginState.map((field, index) => (
                            <Input
                                key={field.id}
                                handleChange={(e) => handleChange(e, index)}
                                value={field.value}
                                labelText={field.labelText}
                                labelFor={field.labelFor}
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                isRequired={field.isRequired}
                                placeholder={typeof field.placeholder === 'string' ? field.placeholder : undefined}
                                customClass="rounded-lg"
                            />
                        ))}
                        <div className="flex items-center mx-6">
                            <input
                                type="checkbox"
                                className="rounded border-stroke text-primary"
                                checked={rememberMe}
                                onChange={handleCheckboxChange}
                            />
                            <p className="text-dark1 text-bodyMM ml-3">Remember me</p>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-col gap-y-6 px-6 mobXS:items-start mobXS:justify-self-start tabMD:items-center tabMD:justify-self-center">
                    <ButtonPrimary
                        text="Sign in"
                        type="submit"
                        padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
                        fontsize="text-bodyMR"
                        width="mobXS:w-full tabMD:w-1/2 desktop:w-full"
                    />
                    <ButtonSecondary
                        borderColor="border-primary"
                        text="Sign in with Google"
                        fontsize="text-bodyMR"
                        padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
                        color="text-dark3"
                        bgColor="bg-white"
                        width="mobXS:w-full tabMD:w-1/2 desktop:w-full"
                        iconType="google"
                        customClass="justify-center"
                    />
                    <ButtonSecondary
                        borderColor="border-primary"
                        text="Sign in with Apple"
                        fontsize="text-bodyMR"
                        padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
                        color="text-dark3"
                        bgColor="bg-white"
                        width="mobXS:w-full tabMD:w-1/2 desktop:w-full"
                        iconType="apple"
                        customClass="justify-center"
                    />
                </div>
                <div className="flex justify-center">
                    <a className="text-primary text-bodyMM" href="/#">
                        Forgot password
                    </a>
                </div>
                <div className="flex justify-center pb-10">
                    <p className="text-dark6 text-bodyMM">
                        Don’t have an account?
                        <a className="text-primary text-bodyMM ml-2" href="/register">
                            Sign up now
                        </a>
                    </p>
                </div>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default LoginPage;
