import React from 'react';
import ButtonAddNew from "../../buttons/ButtonAddNew";
import SortBy from "./SortBy";
import {useLocation} from "react-router-dom";

const NavigationMyMessages = ({toggleTestDiv}) => {
    const location = useLocation();
    const currentPage = location.pathname.split('/').pop();
    return (
        <div>
            <div className="desktop:flex desktop:flex-row desktop:items-center">
                <div className="mobile:p-6 mobile:bg-white">
                    <div className="buttons-top mobile:flex mobile:flex-row justify-between cursor-pointer">
                        <a href="/dashboard"
                           className={`page hidden ${currentPage === 'dashboard' ? 'active' : ''} text-bodyMM text-primary px-[24px] py-[8px]`}>Drafts</a>
                        <a href="/dashboard/my-messages" className="text-bodyMM text-primary px-[24px] py-[8px]">My
                            messages</a>
                    </div>
                </div>
                <div className="mobile:p-6 mobile:bg-gray2 desktop:ml-auto">
                    <div className="filters-top flex flex-row justify-between cursor-pointer relative">
                        <ButtonAddNew className="desktop:hidden" textButton="Add new" onClick={toggleTestDiv}/>
                        <ButtonAddNew className="mobile:hidden" textButton="New message" onClick={toggleTestDiv}/>
                        <SortBy className="desktop:hidden" textSort={'Sort by'}
                                items={['Date', 'Recipient name', 'Subject']}/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default NavigationMyMessages;