import React from 'react';

const TrashIcon = ({className}) => {
    return (
        <div>
            <img src="/trash.svg" alt="trash" className={`w-[16px] items-center ${className}`}/>
        </div>
    );
};

export default TrashIcon;