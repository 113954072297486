export default function Cards() {
  const data = [
    {
      title: "Full Access",
      text: "Enjoy complete control over crafting and managing your digital legacy.",
    },
    {
      title: "Unlimited Rules",
      text: "Shape and share your digital assets without restrictions.",
    },
    {
      title: "Secure Sharing",
      text: "Safely distribute account logins, memories, and crypto wallet details.",
    },
  ];

  return (
    <>
      {data.map(
        (
          item,
          index, // Iterați prin data, nu prin data.items
        ) => (
          <div key={index} className="text-center bg-white rounded-lg p-9">
            <h6 className="text-h5 text-primary pb-5">{item.title}</h6>
            <p className="text-dark5 text-bodyESR">{item.text}</p>
          </div>
        ),
      )}
    </>
  );
}
