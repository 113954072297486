export default function Media({ rounded }) {
  return (
    <>
      <img
        className={` mobile:w-full ${rounded}`}
        src="/section1.png"
        alt="full-shot-parents-kids-nature"
      />

      <div className="relative">
        <img
          className="desktop:hidden mobile:hidden tabMD:hidden absolute right-8 bottom-1 bg-white p-3 rounded-rounded10"
          alt="obect-svg"
          src="/objects.svg"
        />
      </div>

      <div className="relative">
        <img
          className="desktop:hidden absolute border right-5 bottom-1 bg-white mobXS:p-3 p-6 rounded-rounded10"
          alt="obect-svg"
          src="/objects.svg"
        />
      </div>

      <div className="relative">
        <img
          className="mobile:hidden absolute border right-[-25px] bottom-[120px] initialDesktop:right-[0px] xxxlgDesktop:bottom-[210px] xxxlgDesktop:right-[45px] initialDesktop:bottom-[215px] bg-white p-3 rounded-rounded10"
          alt="obect-svg"
          src="/objects.svg"
        />
      </div>
    </>
  );
}
