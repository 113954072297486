import ButtonSecondary from "../../../buttons/ButtonSecondary";
import Cards from "../elements/Cards";

export default function Mobile() {
  return (
    <div className="flex flex-row py-20 desktop:hidden">
      <div className="title-subtitle">
        <h4 className="text-white text-h4 text-center justify-center">
          Pricing
        </h4>
        <p className="text-white text-center text-bodyLR py-8">
          Craft your digital legacy effortlessly with{" "}
          <span className="text-white text-bodyLB">Testament.digital</span>.
          Enjoy full control, unlimited rules, secure sharing—all for{" "}
          <span className="text-secondary text-bodyLB">just $12 per year</span>.
          Your legacy, your way, at an affordable price.
        </p>
        <div className="price">
          <p className="text-center text-white text-h2">
            <span className="text-secondary text-h6">ONLY</span>12${" "}
            <span className="text-h6 text-white">/Year</span>
          </p>
        </div>
        <div className="flex tabMD:items-center tabMD:justify-center py-8">
          <ButtonSecondary
            text="Get Started Now"
            width="mobXS:w-full"
            borderColor="border-secondary"
            padding="mobXS:py-[14px] tabMD:px-[24px] tabMD:py-[14px]"
            fontsize="text-bodyLR"
            bgColor="bg-secondary"
            color="text-white"
          />
        </div>
        <div className="cards">
          <h5 className="text-white text-h5 text-center pb-10">
            What’s included:
          </h5>
          <div className="flex flex-col space-y-5">
            <Cards section="What’s included:" />
          </div>
        </div>
      </div>
    </div>
  );
}
