import Desktop from "./screen-view/Desktop";
import Mobile from "./screen-view/Mobile";

export default function SectionFive() {
  return (
    <section
      className="section-five bg-gradient-to-b from-customBlue-dark mobile:px-6 desktop:mt-28 to-customBlue"
      id="section-5"
    >
      <Desktop />
      <Mobile />
    </section>
  );
}
