const loginFields = [
  {
    labelText: "Your email",
    labelFor: "email-address",
    id: "email-address",
    value: "",
    name: "email",
    type: "text",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Your email",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    value: "",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Your password",
  }
];

const signupFields = [
  {
    labelText: "Full Name",
    labelFor: "full-name",
    id: "full-name",
    value: "",
    name: "name",
    type: "text",
    autoComplete: "name",
    isRequired: true,
    placeholder: "Your name",
  },
  {
    labelText: "Your email",
    labelFor: "email-address",
    id: "email-address",
    value: "",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Your email",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    value: "",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Your password",
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirm-password",
    id: "confirm-password",
    value: "",
    name: "confirm-password",
    type: "password",
    autoComplete: "confirm-password",
    isRequired: true,
    placeholder: "Confirm Password",
  }
];

const settingsFields = [
  {
    labelText: "Notification frequency",
    labelFor: "notifications",
    id: "notifications-field",
    name: "notification_frequency_id",
    type: "select",
    autoComplete: "off",
    isRequired: true,
    placeholder: false,
    options: [],
  },
  {
    labelText: "Notification method",
    labelFor: "notifications-method",
    id: "notifications-method-field",
    name: "notification_method_id",
    type: "select",
    autoComplete: "off",
    isRequired: true,
    placeholder: false,
    options: [],
  },
  {
    labelText: "Number of emails",
    labelFor: "number-email",
    id: "number-email-field",
    name: "number_of_emails",
    type: "number",
    autoComplete: "off",
    isRequired: true,
    placeholder: false,
  },
  {
    labelText: "Email sending frequency (in days)",
    labelFor: "email-sending-frequency",
    id: "email-sending-frequency-field",
    name: "email_sending_frequency",
    type: "number",
    autoComplete: "off",
    isRequired: true,
    placeholder: false,
  },
];
export { loginFields, signupFields, settingsFields };
