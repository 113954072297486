export default function FooterElements({ title, elements }) {
  return (
    <div>
      <h6 className="text-h6 pb-3 text-black1 mobXS:text-center tabMD:text-start">
        {title}
      </h6>
      <ul>
        {elements.map((element) => (
          <li
            key={element.url || element.text}
            className="pb-3 mobXS:text-center tabMD:text-start"
          >
            {element.url ? (
              <a
                href={element.url}
                className="text-gray3"
                target="_blank"
                rel="noreferrer"
              >
                {element.text}
              </a>
            ) : (
              <p className="text-gray3">{element.text}</p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
