import { useNavigate } from "react-router-dom";

export default function DynamicNav({ links, hover, navigateTo }) {
    const navigate = useNavigate();

    const handleClick = (e, url) => {
        e.preventDefault();
        if (navigateTo) {
            navigate(url);
        } else {
            const element = document.getElementById(url.slice(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    return (
        <>
            {links.map((link) => (
                <div className={`flex cursor-pointer ${hover} pb-3`} key={link.url}>
                    {link.src && <img src={link.src} alt="icon" className="px-4 pt-3" />}
                    <a
                        href={link.url}
                        onClick={(e) => handleClick(e, link.url)}
                        className="text-dark5 xl:text-menuMSB xxl:text-bodyLR pt-3 justify-start"
                    >
                        {link.text}
                    </a>
                </div>
            ))}
        </>
    );
}
