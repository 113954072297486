import React, {useEffect, useState} from 'react';
import {
    useStripe,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement, useElements,
} from '@stripe/react-stripe-js';
import axiosConfig from "../axiosConfig";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useAuth} from "../contexts/AuthProvider";
import Cookies from "js-cookie";

const CheckoutPage = ({intent}) => {
        const stripe = useStripe();
        const elements = useElements();
        const params = useParams();
        const navigate = useNavigate();
        const planId = params.planId;
        const handleSubmit = async (event) => {
            event.preventDefault();
            const accessToken = Cookies.get('AccessToken');

            if (!stripe || !elements) {
                return;
            }

            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
            });
            const paymentMethod = payload.paymentMethod.id;
            console.log(paymentMethod);

            if (payload.error) {
                toast.error(payload.error.message);
            } else {
                try {
                    if (!accessToken) {
                        return;
                    }
                    const config = {
                        withCredentials: true,
                    };
                    const { REACT_APP_BASE } = process.env;
                    const response = await axios.post(
                        `http://localhost:80/api/actions/api/actions/plans/process`,
                        {
                            paymentMethod,
                            planId,
                        },
                        config
                    );
                    if (response.data.success) {
                        navigate('/dashboard/success');
                        console.log('Plata a fost procesată cu succes!');
                    } else {
                        toast.error('Plata a eșuat', response.data.error);
                    }
                } catch (error) {
                    toast.error('Eroare în timpul procesării plății', error.data);
                }
            }
        };

        return (
            <div
                className="get-plan-form bg-white rounded-lg mobXS:mx-6 tabMD:mx-6 my-10 border desktop:w-1/4 desktop:mx-auto p-6 space-y-6 shadow-shadow5">
                <h6 className="text-h6 text-dark4">Payment details</h6>
                <p className="text-bodyMR text-black">You will be charged <span
                    className="text-bodyMS text-primary">11$</span>.Billed anually.</p>
                <div className="flex flex-col space-y-9">
                    <form onSubmit={handleSubmit} className="space-y-9">
                        <div className="form-group">
                            <label htmlFor="card-number" className="text-bodyMM text-dark1">Card Number</label>
                            <CardNumberElement id="card-number" className="border rounded p-3"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="card-expDate" className="text-bodyMM text-dark1">Expiry Date</label>
                            <CardExpiryElement id="card-expDate" className="border rounded p-3"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="card-cvv" className="text-bodyMM text-dark1">CVV</label>
                            <CardCvcElement id="card-cvv" className="border rounded p-3"/>
                        </div>
                        <button type="submit"
                                className="border bg-primary border-primary rounded px-[8px] py-[10px] text-white"
                                disabled={!stripe}>Complete subscription
                        </button>
                    </form>
                    <ToastContainer
                        position="top-center"
                        autoClose={7000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            </div>
        );
    }
;

export default CheckoutPage;
