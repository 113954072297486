import SlideComponent from "../elements/SlideComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Desktop() {
  const slides = [
    {
      stepTxt: "Step 1",
      info: "Sign Up and Subscribe",
      image: "/slide1.png",
      description: [
        "Easily create an account.",
        "Securely pay $12 per year through our encrypted gateway.",
        "Instantly access the full range of Testament.Digital features.",
      ],
    },
    {
      stepTxt: "Step 2",
      info: "Upload and Organize Files",
      image: "/slider2.png",
      description: [
        "Effortlessly upload and manage your important files.",
        "Organize your digital assets with our intuitive system.",
        "Rest easy knowing your files are securely stored for future sharing.",
      ],
    },
    {
      stepTxt: "Step 3",
      info: "Craft Your Messages",
      image: "/slider3.png",
      description: [
        "Access our user-friendly message creation interface.",
        "Create heartfelt messages and attach personal files.",
        "Edit messages anytime to ensure they reflect your intentions.",
      ],
    },
  ];

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 700,
    time: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="mobile:hidden ">
      <div className="xl:container xl:px-16 xxl:px-0 xxl:mx-auto">
        <h4 className="text-h3 text-dark3 text-start pt-20 justify-start ">
          How it works
        </h4>
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <SlideComponent key={index} {...slide} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
