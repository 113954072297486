import {createBrowserRouter, Navigate} from "react-router-dom";
import ProtectedLayout from "./layouts/ProtectedLayout";
import PublicLayout from "./layouts/PublicLayout";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/HomePage";
import BillingPlanPage from "./pages/BillingPlanPage";

const router = createBrowserRouter([
    {
        path: "/dashboard/*",
        element: <ProtectedLayout />,
    },
    {
        // Define routes for public pages
        path: '/',
        element: < PublicLayout/>,
        children: [
            {
                path: "/",
                element: < HomePage />
            },
            {
                path: "/register",
                element: < RegisterPage />
            },
            {
                path: "/login",
                element: < LoginPage />
            },
        ]
    },
    {
        // Define route for not found page
        path: "*",
        element: < NotFoundPage/>
    }

]);
export  default router;