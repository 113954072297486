import Mobile from "./screen-view/Mobile";
import Desktop from "./screen-view/Desktop";

export default function SectionTwo() {
  return (
    <section className="section-two bg-white" id="section-2">
      <Mobile />
      <Desktop />
    </section>
  );
}
